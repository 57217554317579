// src/pages/SurveyBuilder.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Survey.css';

const SurveyBuilder = () => {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [questionType, setQuestionType] = useState('text');
  const [surveyTitle, setSurveyTitle] = useState('');
  const [options, setOptions] = useState([]); // Untuk nilai opsi
  const [optionInput, setOptionInput] = useState(''); // Input opsi baru

  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const response = await axios.get('https://apps.respsourcing.com/survey.php');
        setQuestions(response.data.questions || []);
        setSurveyTitle(response.data.title || '');
      } catch (error) {
        console.error('Error fetching survey data:', error);
      }
    };

    fetchSurveyData();
  }, []);

  // Tambah pertanyaan baru
  const handleAddQuestion = () => {
    if (!newQuestion) return;

    const question = {
      text: newQuestion,
      type: questionType,
      options: ['select_one', 'select_multiple', 'dropdown', 'search'].includes(questionType) ? options : [],
    };

    setQuestions([...questions, question]);
    setNewQuestion('');
    setQuestionType('text');
    setOptions([]);
  };

  // Tambah opsi ke pertanyaan
  const handleAddOption = () => {
    if (!optionInput) return;

    setOptions([...options, optionInput]);
    setOptionInput('');
  };

  // Hapus pertanyaan
  const handleRemoveQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  // Simpan survei ke database
  const handleSaveSurvey = async () => {
    try {
      const response = await axios.post('https://apps.respsourcing.com/survey.php', {
        title: surveyTitle,
        questions,
      });
  
      console.log('Server Response:', response.data);
  
      if (response.data.success) {
        alert('Survey saved successfully');
      } else {
        alert('Failed to save survey: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error saving survey:', error);
      alert('Error saving survey. Please try again.');
    }
  };

  return (
    <div className="survey-builder-container">
      <h2>Survey Builder</h2>

      <div className="survey-title">
        <label>Survey Title:</label>
        <input
          type="text"
          value={surveyTitle}
          onChange={(e) => setSurveyTitle(e.target.value)}
          placeholder="Enter survey title"
        />
      </div>

      <div className="add-question">
        <label>New Question:</label>
        <input
          type="text"
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
          placeholder="Enter your question"
        />
        <label>Type:</label>
        <select value={questionType} onChange={(e) => setQuestionType(e.target.value)}>
          <option value="text">Text</option>
          <option value="select_one">Select One</option>
          <option value="select_multiple">Select Multiple</option>
          <option value="integer">Integer</option>
          <option value="decimal">Decimal</option>
          <option value="photo">Photo</option>
          <option value="dropdown">Dropdown</option>
          <option value="searching">Searching</option>
          <option value="gps_point">GPS Point</option>
          <option value="gps_polygon">GPS Polygon</option>
        </select>
        <button onClick={handleAddQuestion}>Add Question</button>
      </div>

      {['select_one', 'select_multiple', 'dropdown', 'searching'].includes(questionType) && (
        <div className="add-options">
          <label>Options:</label>
          <input
            type="text"
            value={optionInput}
            onChange={(e) => setOptionInput(e.target.value)}
            placeholder="Enter option value"
          />
          <button onClick={handleAddOption}>Add Option</button>
          <div className="options-list">
            {options.map((option, index) => (
              <span key={index} className="option-item">
                {option}
              </span>
            ))}
          </div>
        </div>
      )}

      <div className="questions-list">
        <h3>Questions</h3>
        {questions.map((question, index) => (
          <div key={index} className="question-item">
            <span>{index + 1}. {question.text} ({question.type})</span>
            {question.options?.length > 0 && (
              <ul>
                {question.options.map((option, i) => (
                  <li key={i}>{option}</li>
                ))}
              </ul>
            )}
            <button onClick={() => handleRemoveQuestion(index)}>Remove</button>
          </div>
        ))}
      </div>

      <button onClick={handleSaveSurvey} className="save-survey-button">Save Survey</button>
    </div>
  );
};

export default SurveyBuilder;
