// src/components/SupplierTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/SupplierTable.css';

const CloneTable = () => {
  const [Clone, setClone] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch data from API
  useEffect(() => {
    const fetchClone = async () => {
      try {
        const response = await axios.get('https://apps.respsourcing.com/clone.php');
        console.log('Clone Data:', response.data); // Debugging: memastikan struktur data
        setClone(response.data);
      } catch (error) {
        console.error('Error fetching clone data:', error);
      }
    };

    fetchClone();
  }, []);

  // Filter dan pencarian
  const filteredClone = Clone.filter((clone) => {
    const name = clone.clone_name || ''; // Pastikan properti ada
    return name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="supplier-table-container">
      <div className="supplier-actions">
        <input
          type="text"
          placeholder="Search Clone"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <button onClick={() => setIsModalOpen(true)} className="add-button">Add New Clone</button>
      </div>

      <table className="supplier-table">
        <thead>
          <tr>
            <th>Clone ID</th>
            <th>Clone Name</th>
          </tr>
        </thead>
        <tbody>
          {filteredClone.map((clone, index) => (
            <tr key={index}>
              <td>{clone.clone_id || 'N/A'}</td>
              <td>{clone.clone_name || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <AddCloneModal
          onClose={() => setIsModalOpen(false)}
          onAddClone={(newClone) => {
            // Tambahkan supplier baru ke state setelah berhasil disimpan di database
            setClone([...Clone, newClone]);
          }}
        />
      )}
    </div>
  );
};

// Komponen Pop-up Form untuk Add New Supplier
const AddCloneModal = ({ onClose, onAddClone }) => {
  const [newClone, setNewClone] = useState({
    clone_id: '',
    clone_name: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewClone((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Kirim data ke server menggunakan POST
      const response = await axios.post('https://apps.respsourcing.com/clone.php', newClone);
      console.log('Add Supplier Response:', response.data);
  
      if (response.data.success) {
        onAddClone(newClone); // Update state di tabel
        alert('Clone added successfully!');
      } else {
        alert('Failed to add clone: ' + response.data.message);
      }
  
      onClose(); // Tutup modal
    } catch (error) {
      console.error('Error adding clone:', error);
      alert('Error adding clone. Please try again.');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add New Clone</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" name="fo_id" placeholder="Clone ID" value={newClone.clone_id} onChange={handleChange} required />
          <input type="text" name="country" placeholder="Clone Name" value={newClone.clone_name} onChange={handleChange} required />
          <button type="submit">Add Clone</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default CloneTable;