// src/components/MainLayout.js
import React from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';
import '../styles/MainLayout.css';

const MainLayout = () => {
  return (
    <div className="main-layout">
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <div className="content-area">
          <Outlet /> {/* Menampilkan komponen berdasarkan rute */}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
