import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver'; // Tambahkan ini
import * as XLSX from 'xlsx'; // Tambahkan ini
import '../styles/SurveyResults.css';

const SurveyResults = () => {
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        const response = await axios.get('https://apps.respsourcing.com/survey.php');
        setSurveys(response.data);
      } catch (error) {
        console.error('Error fetching surveys:', error);
      }
    };

    fetchSurveys();
  }, []);

  const fetchResults = async (surveyId) => {
    try {
      const response = await axios.get(`https://apps.respsourcing.com/survey_results.php?survey_id=${surveyId}`);
      setResults(response.data);
    } catch (error) {
      console.error('Error fetching survey results:', error);
    }
  };

  const handleSurveyChange = (e) => {
    const surveyId = e.target.value;
    setSelectedSurvey(surveyId);
    if (surveyId) {
      fetchResults(surveyId);
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(results);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Survey Results');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, `survey_results_${selectedSurvey}.xlsx`);
  };

  if (!selectedSurvey || results.length === 0) {
    return (
      <div className="survey-results-container">
        <h2>Survey Results</h2>
        <label>Select a Survey:</label>
        <select onChange={handleSurveyChange} defaultValue="">
          <option value="" disabled>Select a survey</option>
          {surveys.map((survey) => (
            <option key={survey.id} value={survey.id}>
              {survey.title}
            </option>
          ))}
        </select>
        <p>No data available. Please select a survey.</p>
      </div>
    );
  }

  const columns = Object.keys(results[0]);

  return (
    <div className="survey-results-container">
      <h2>Survey Results</h2>
      <div className="survey-actions">
        <label>Select a Survey:</label>
        <select onChange={handleSurveyChange} value={selectedSurvey}>
          <option value="" disabled>Select a survey</option>
          {surveys.map((survey) => (
            <option key={survey.id} value={survey.id}>
              {survey.title}
            </option>
          ))}
        </select>
        <button onClick={exportToExcel} className="export-button">Export to Excel</button>
      </div>

      <table className="survey-results-table">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {results.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col, colIndex) => (
                <td key={colIndex}>{row[col] || 'N/A'}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SurveyResults;
