// src/components/Navbar.js
import React from 'react';
import { FaUserCircle, FaCog } from 'react-icons/fa';
import '../styles/Navbar.css';

const Navbar = () => {
  return (
    <div className="navbar">
      <h1>Dashboard</h1>
      <div className="navbar-right">
        <FaUserCircle size={24} />
        <FaCog size={24} style={{ marginLeft: '10px' }} />
      </div>
    </div>
  );
};

export default Navbar;