// src/components/WorkplanTable.js
import React, { useState, useRef } from 'react';
import '../styles/Workplan.css';
import * as XLSX from 'xlsx';

const initialRow = {
    Target: '',
    KeyDeliveries: '',
    Activities: '',
    StartDate: '',
    EndDate: '',
    Status: '',
    TotalAch: '',
    PercentAch: '',
    Q1Ach: '',
    Q2Ach: '',
    Q3Ach: '',
    Q4Ach: '',
  };
  
  const WorkplanTable = () => {
    const [rows, setRows] = useState([initialRow]);
    const [columnWidths, setColumnWidths] = useState({});
    const tableRef = useRef(null);
  
    const addRow = () => {
      setRows([...rows, { ...initialRow }]);
    };
  
    const handleChange = (index, field, value) => {
      const newRows = [...rows];
      newRows[index][field] = value;
      setRows(newRows);
    };
  
    const startResizing = (e, field) => {
      e.preventDefault();
      const startX = e.clientX;
      const startWidth = columnWidths[field] || 100;
  
      const handleMouseMove = (event) => {
        const newWidth = startWidth + (event.clientX - startX);
        setColumnWidths((prev) => ({ ...prev, [field]: newWidth }));
      };
  
      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
  
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };
  
    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Workplan');
      XLSX.writeFile(workbook, 'Workplan.xlsx');
    };
  
    const importFromExcel = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (evt) => {
        const data = new Uint8Array(evt.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const importedData = XLSX.utils.sheet_to_json(worksheet);
        setRows(importedData);
      };
  
      reader.readAsArrayBuffer(file);
    };
  
    return (
      <div className="workplan-table-container">
        {/* Menu aksi tetap di tempat */}
        <div className="table-actions">
          <button onClick={addRow} className="add-row-button">Add Row</button>
          <button onClick={exportToExcel} className="export-button">Export to Excel</button>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={importFromExcel}
            className="import-input"
          />
        </div>
  
        {/* Wrapper untuk tabel scrollable */}
        <div className="workplan-table-wrapper">
          <table className="workplan-table" ref={tableRef}>
            <thead>
              <tr>
                {Object.keys(initialRow).map((field, index) => (
                  <th
                    key={field}
                    style={{ width: columnWidths[field] || '100px' }}
                    className={index < 3 ? "sticky-column resizable-header" : "resizable-header"}
                  >
                    {field.replace(/([A-Z])/g, ' $1')}
                    <div
                      className="resize-handle"
                      onMouseDown={(e) => startResizing(e, field)}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  {Object.keys(row).map((field, idx) => (
                    <td
                      key={field}
                      className={idx < 3 ? "sticky-column" : ""}
                      style={{ width: columnWidths[field] || '100px' }}
                    >
                      <input
                        type="text"
                        value={row[field]}
                        onChange={(e) => handleChange(index, field, e.target.value)}
                        style={{ width: columnWidths[field] || '100px' }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  
  export default WorkplanTable;