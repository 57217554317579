// src/pages/SurveyForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/SurveyForm.css';

const SurveyForm = () => {
  const [surveys, setSurveys] = useState([]); // Daftar survei
  const [selectedSurvey, setSelectedSurvey] = useState(null); // Survei yang dipilih
  const [responses, setResponses] = useState({}); // Jawaban survei

  // Fetch daftar survei dari API
  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        const response = await axios.get('https://apps.respsourcing.com/survey.php'); // API untuk daftar survei
        setSurveys(response.data);
      } catch (error) {
        console.error('Error fetching surveys:', error);
      }
    };

    fetchSurveys();
  }, []);

  // Handle pemilihan survei
  const handleSelectSurvey = async (surveyId) => {
    try {
      const response = await axios.get(`https://apps.respsourcing.com/survey.php?survey_id=${surveyId}`);
      setSelectedSurvey(response.data);
      setResponses({}); // Reset jawaban
    } catch (error) {
      console.error('Error fetching selected survey:', error);
    }
  };

  // Handle perubahan jawaban
  const handleChange = (questionId, value) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  // Submit survei
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://apps.respsourcing.com/survey_responses.php', {
        survey_id: selectedSurvey.id,
        responses,
      });

      if (response.data.success) {
        alert('Survey submitted successfully');
      } else {
        alert('Failed to submit survey: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error submitting survey:', error);
      alert('Error submitting survey. Please try again.');
    }
  };

  return (
    <div className="survey-form-container">
      <h2>Survey Form</h2>

      <div className="survey-selection">
        <label>Select a Survey:</label>
        <select onChange={(e) => handleSelectSurvey(e.target.value)} defaultValue="">
          <option value="" disabled>Select a survey</option>
          {surveys.map((survey) => (
            <option key={survey.id} value={survey.id}>
              {survey.title}
            </option>
          ))}
        </select>
      </div>

      {selectedSurvey && (
        <>
          <h3>{selectedSurvey.title}</h3>
          <form onSubmit={handleSubmit}>
            {selectedSurvey.questions.map((question) => (
              <div key={question.id} className="survey-question">
                <label>{question.text}</label>
                {renderInputField(question, responses[question.id], (value) => handleChange(question.id, value))}
              </div>
            ))}
            <button type="submit" className="submit-survey-button">Submit Survey</button>
          </form>
        </>
      )}
    </div>
  );
};

// Render input field based on question type
const renderInputField = (question, value, onChange) => {
  switch (question.type) {
    case 'text':
      return <input type="text" value={value || ''} onChange={(e) => onChange(e.target.value)} />;
    case 'integer':
      return <input type="number" value={value || ''} onChange={(e) => onChange(e.target.value)} />;
    case 'decimal':
      return <input type="number" step="0.01" value={value || ''} onChange={(e) => onChange(e.target.value)} />;
    case 'dropdown':
      return (
        <select value={value || ''} onChange={(e) => onChange(e.target.value)}>
          <option value="">Select an option</option>
          {question.options.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      );
      case 'select_one':
        return (
          <div className="radio-group">
            {question.options.map((option, index) => (
              <label key={index}>
                <input
                  type="radio"
                  name={`question_${question.id}`} // Berikan nama unik untuk setiap grup radio
                  value={option}
                  checked={value === option}
                  onChange={(e) => onChange(e.target.value)} // Kirim nilai yang dipilih
                />
                {option}
              </label>
            ))}
          </div>
        );
    case 'select_multiple':
      return (
        <div className="checkbox-group">
          {question.options.map((option, index) => (
            <label key={index}>
              <input
                type="checkbox"
                value={option}
                checked={(value || []).includes(option)}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const newValue = checked
                    ? [...(value || []), option]
                    : (value || []).filter((v) => v !== option);
                  onChange(newValue);
                }}
              />
              {option}
            </label>
          ))}
        </div>
      );
    case 'photo':
      return <input type="file" accept="image/*" onChange={(e) => onChange(e.target.files[0])} />;
    case 'gps_point':
      return <button type="button" onClick={() => getGPSPoint(onChange)}>Get GPS Point</button>;
    case 'gps_polygon':
      return <button type="button" onClick={() => getGPSPolygon(onChange)}>Get GPS Polygon</button>;
    default:
      return null;
  }
};

// Mock functions for GPS data
const getGPSPoint = (onChange) => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      onChange(`${position.coords.latitude}, ${position.coords.longitude}`);
    },
    (error) => {
      console.error('Error getting GPS point:', error);
      alert('Failed to get GPS point');
    }
  );
};

const getGPSPolygon = (onChange) => {
  alert('Mock function: implement GPS polygon collection');
  onChange('Mock GPS Polygon Data');
};

export default SurveyForm;