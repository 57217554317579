// src/components/Sidebar.js
import React from 'react';
import { FaHome, FaChartLine, FaUsers, FaClipboardList, FaMapMarkedAlt, FaTree, FaDatabase, FaLaptop, FaEquals } from 'react-icons/fa';
import '../styles/Sidebar.css';
import SidebarItem from './SidebarItems';
import logo from '../assets/images/Sourcing.png';

const Sidebar = () => {
    const menuItems = [
        { title: 'Home', icon: <FaHome />, subItems: [] },
        { title: 'Dashboard', icon: <FaChartLine />, subItems: [
          { name: 'KPI', path: '/dashboard/kpi' }
        ] },
        { title: 'Supplier & FO', icon: <FaUsers />, subItems: [
          { name: 'Supplier List', path: '/supplier-&-fo/supplier-list'}
        ] },
        { title: 'Workspace', icon: <FaLaptop />, subItems: [] },
        { title: 'KPI & Workplan', icon: <FaClipboardList />, subItems: [
          { name:'KPI', path: 'kpi-&-workplan/kpi'},
          { name:'Workplan', path: 'kpi-&-workplan/workplan'}
        ] },
        { title: 'Field Monitoring', icon: <FaMapMarkedAlt />, subItems: [
            {name:'Farmers Sample', path: '/field-monitoring/farmers-sample'},
            {name:'Monitoring Form', path: '/field-monitoring/monitoring-form'},
        ] },
        { title: 'Multiclone', icon: <FaTree />, subItems: [] },
        { title: 'Raw Data', icon: <FaDatabase />, subItems: [
            {name:'Field Monitoring Data', path: '/raw-data/field-monitoring-data'},
        ] },
        { title: 'Variables', icon: <FaEquals />, subItems: [
            {name:'List Clone', path: '/variables/list-clone'},
            {name:'Questions Builder', path: '/variables/questions-builder'},
        ] },
    ];

    return (
        <div className="sidebar">
            <div className='Logo'>
                <img src={logo} alt="Logo" className="logo-image" />
            </div>
            <div className="sidebar-menu">
                {menuItems.map((item, index) => (
                    <SidebarItem key={index} item={item} />
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
