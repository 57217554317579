// src/components/SupplierTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/SupplierTable.css';

const SupplierTable = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');

  // Fetch data from API
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get('https://apps.respsourcing.com/fo.php');
        console.log('Suppliers Data:', response.data); // Debugging: memastikan struktur data
        setSuppliers(response.data);
      } catch (error) {
        console.error('Error fetching suppliers data:', error);
      }
    };

    fetchSuppliers();
  }, []);

  // Filter dan pencarian
  const filteredSuppliers = suppliers.filter((supplier) => {
    const name = supplier.supplier || ''; // Pastikan properti ada
    const area = supplier.area || ''; // Pastikan properti area ada

    return name.toLowerCase().includes(searchTerm.toLowerCase()) &&
           (filter === '' || area === filter);
  });

  return (
    <div className="supplier-table-container">
      <div className="supplier-actions">
        <input
          type="text"
          placeholder="Search Supplier"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <select onChange={(e) => setFilter(e.target.value)} className="filter-select">
          <option value="">All Areas</option>
          <option value="Area 1">Area 1</option>
          <option value="Area 2">Area 2</option>
        </select>
        <button onClick={() => setIsModalOpen(true)} className="add-button">Add New Supplier</button>
      </div>

      <table className="supplier-table">
        <thead>
          <tr>
            <th>Country</th>
            <th>Supplier Name</th>
            <th>FO / CH</th>
            <th>Area</th>
            <th>KPI</th>
            <th>Workplan</th>
          </tr>
        </thead>
        <tbody>
          {filteredSuppliers.map((supplier, index) => (
            <tr key={index}>
              <td>{supplier.country || 'N/A'}</td>
              <td>{supplier.supplier || 'N/A'}</td>
              <td>{supplier.fo_name || 'N/A'}</td>
              <td>{supplier.area || 'N/A'}</td>
              <td>{supplier.kpi || 'N/A'}</td>
              <td>{supplier.workplan || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <AddSupplierModal
          onClose={() => setIsModalOpen(false)}
          onAddSupplier={(newSupplier) => {
            // Tambahkan supplier baru ke state setelah berhasil disimpan di database
            setSuppliers([...suppliers, newSupplier]);
          }}
        />
      )}
    </div>
  );
};

// Komponen Pop-up Form untuk Add New Supplier
const AddSupplierModal = ({ onClose, onAddSupplier }) => {
  const [newSupplier, setNewSupplier] = useState({
    fo_id: '',
    country: '',
    supplier: '',
    fo_name: '',
    area: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSupplier((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Kirim data ke server menggunakan POST
      const response = await axios.post('https://apps.respsourcing.com/fo.php', newSupplier);
      console.log('Add Supplier Response:', response.data);
  
      if (response.data.success) {
        onAddSupplier(newSupplier); // Update state di tabel
        alert('Supplier added successfully!');
      } else {
        alert('Failed to add supplier: ' + response.data.message);
      }
  
      onClose(); // Tutup modal
    } catch (error) {
      console.error('Error adding supplier:', error);
      alert('Error adding supplier. Please try again.');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add New Supplier</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" name="fo_id" placeholder="FO ID" value={newSupplier.fo_id} onChange={handleChange} required />
          <input type="text" name="country" placeholder="Country" value={newSupplier.country} onChange={handleChange} required />
          <input type="text" name="supplier" placeholder="Supplier" value={newSupplier.supplier} onChange={handleChange} required />
          <input type="text" name="fo_name" placeholder="FO / CH" value={newSupplier.fo_name} onChange={handleChange} required />
          <input type="text" name="area" placeholder="Area" value={newSupplier.area} onChange={handleChange} required />
          <button type="submit">Add Supplier</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default SupplierTable;