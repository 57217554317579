// src/pages/FarmersSample.js
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import '../styles/Farmer_sample.css';

const FarmersSample = () => {
  const [farmers, setFarmers] = useState([]);

  // Fetch data from API
  useEffect(() => {
    const fetchFarmers = async () => {
      try {
        const response = await axios.get('https://apps.respsourcing.com/farmers_sample.php');
        setFarmers(response.data);
      } catch (error) {
        console.error('Error fetching farmers data:', error);
      }
    };
    fetchFarmers();
  }, []);

  // Export to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(farmers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Farmers');
    XLSX.writeFile(workbook, 'FarmersSample.xlsx');
  };

  // Import from Excel
  const importFromExcel = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = new Uint8Array(evt.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const importedData = XLSX.utils.sheet_to_json(worksheet);
      setFarmers(importedData);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="farmers-sample-container">
      <h2>Farmers Sample</h2>
      <div className="table-actions">
        <button onClick={exportToExcel} className="export-button">Export to Excel</button>
        <input type="file" accept=".xlsx, .xls" onChange={importFromExcel} className="import-input" />
      </div>

      <table className="farmers-table">
        <thead>
          <tr>
            <th>Sample Type</th>
            <th>Farmers ID</th>
            <th>Farmers Name</th>
            <th>Farm Unit</th>
            <th>Farm Size</th>
            <th>Village</th>
            <th>Subdistrict</th>
            <th>District</th>
            <th>Institution</th>
            <th>FO</th>
            <th>Monitoring Topic</th>
            <th>Cluster Leader</th>
          </tr>
        </thead>
        <tbody>
          {farmers.map((farmer, index) => (
            <tr key={index}>
              <td>{farmer.sample_type}</td>
              <td>{farmer.farmers_id}</td>
              <td>{farmer.farmer_name}</td>
              <td>{farmer.farm_unit}</td>
              <td>{farmer.farm_size}</td>
              <td>{farmer.village}</td>
              <td>{farmer.subdistrict}</td>
              <td>{farmer.district}</td>
              <td>{farmer.institution}</td>
              <td>{farmer.fo}</td>
              <td>{farmer.sample_for}</td>
              <td>{farmer.user_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FarmersSample;
