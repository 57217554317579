// src/pages/KpiTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Kpi.css';

const KpiTable = () => {
  const [columns, setColumns] = useState(['Column 1', 'Column 2']); // Nama kolom awal
  const [rows, setRows] = useState([]); // Data baris
  const [newColumn, setNewColumn] = useState(''); // Untuk input kolom baru

  // Fetch data awal dari database
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apps.respsourcing.com/kpi.php'); // API GET data
        const { data } = response;
        const columns = Object.keys(data[0] || {}).filter((key) => key !== 'id'); // Exclude ID
        setColumns(columns);
        setRows(data);
      } catch (error) {
        console.error('Error fetching KPI data:', error);
      }
    };

    fetchData();
  }, []);

  // Tambahkan kolom baru
  const handleAddColumn = async () => {
    if (!newColumn) return;

    try {
      const response = await axios.post('https://apps.respsourcing.com/kpi.php', {
        action: 'add_column',
        column_name: newColumn,
      });

      if (response.data.success) {
        setColumns([...columns, newColumn]);
        alert('Column added successfully');
      } else {
        alert('Failed to add column: ' + response.data.message);
      }
      setNewColumn(''); // Reset input
    } catch (error) {
      console.error('Error adding column:', error);
    }
  };

  // Tambahkan baris baru
  const handleAddRow = () => {
    setRows([...rows, {}]); // Tambahkan baris kosong
  };

  // Hapus kolom
  const handleRemoveColumn = async (columnName) => {
    try {
      const response = await axios.post('https://apps.respsourcing.com/kpi.php', {
        action: 'remove_column',
        column_name: columnName,
      });

      if (response.data.success) {
        setColumns(columns.filter((col) => col !== columnName));
        alert('Column removed successfully');
      } else {
        alert('Failed to remove column: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error removing column:', error);
    }
  };

  // Hapus baris
  const handleRemoveRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  // Update nilai sel
  const handleCellChange = (rowIndex, columnName, value) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][columnName] = value;
    setRows(updatedRows);
  };

  // Simpan tabel ke database
  const handleSaveTable = async () => {
    try {
      const response = await axios.post('https://apps.respsourcing.com/kpi.php', {
        action: 'save_table',
        columns,
        rows,
      });

      if (response.data.success) {
        alert('Table saved successfully');
      } else {
        alert('Failed to save table: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error saving table:', error);
    }
  };

  return (
    <div className="kpi-table-container">
      <h2>KPI Table</h2>

      <div className="table-actions">
        <input
          type="text"
          placeholder="New Column Name"
          value={newColumn}
          onChange={(e) => setNewColumn(e.target.value)}
        />
        <button onClick={handleAddColumn}>Add Column</button>
        <button onClick={handleAddRow}>Add Row</button>
        <button onClick={handleSaveTable} className="save-button">Save Table</button>
      </div>

      <table className="kpi-table">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>
                {col}
                <button onClick={() => handleRemoveColumn(col)}>x</button>
              </th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col, colIndex) => (
                <td key={colIndex}>
                  <input
                    type="text"
                    value={row[col] || ''}
                    onChange={(e) =>
                      handleCellChange(rowIndex, col, e.target.value)
                    }
                  />
                </td>
              ))}
              <td>
                <button onClick={() => handleRemoveRow(rowIndex)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default KpiTable;
