// src/components/SidebarItem.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Sidebar.css';

const SidebarItem = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="sidebar-item"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link to={item.path || "#"} className="sidebar-link">
        {item.icon}
        <span className="sidebar-title">{item.title}</span>
      </Link>
      
      {/* Render sub-items on hover */}
      {isHovered && item.subItems && item.subItems.length > 0 && (
        <div className="sidebar-submenu">
          {item.subItems.map((subItem, index) => (
            <Link key={index} to={subItem.path} className="sidebar-submenu-item">
              {subItem.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default SidebarItem;
