// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import Login from './pages/Login';
import Home from './pages/Home';
import Kpi from './pages/Kpi';
import SupplierTable from './pages/SupplierTable';
import KpiTable from './pages/Kpi';
import FarmersSample from './pages/Farmer_sample';
import WorkplanTable from './pages/Workplan';
import CloneTable from './pages/Clone';
import SurveyBuilder from './pages/Survey';
import SurveyForm from './pages/SurveyForm';
import SurveyResults from './pages/SurveyResult';

function AppLayout() {
  const location = useLocation();

  return (
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        
        {/* Halaman dengan layout */}
        <Route element={<MainLayout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/dashboard/kpi" element={<Kpi />} />
          <Route path="/supplier-&-fo/supplier-list" element={<SupplierTable />} />
          <Route path="kpi-&-workplan/kpi" element={<KpiTable />} />
          <Route path="kpi-&-workplan/workplan" element={<WorkplanTable />} />
          <Route path="/field-monitoring/farmers-sample" element={<FarmersSample />} />
          <Route path="//field-monitoring/monitoring-form" element={<SurveyForm />} />
          <Route path="/raw-data/field-monitoring-data" element={<SurveyResults />} />
          <Route path="/variables/list-clone" element={<CloneTable />} />
          <Route path="/variables/questions-builder" element={<SurveyBuilder />} />
        </Route>
      </Routes>
  );
}
function App() {
  return (
    <Router>
      <AppLayout />
    </Router>
  );
}

export default App;
