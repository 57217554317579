// src/pages/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      // Kirim data login ke server menggunakan POST
      const response = await axios.get('https://apps.respsourcing.com/user.php', { username, password });
      
      //console.log('Server Response:', response); // Debugging respons dari server
      const data = response.data;
  
      // Periksa apakah `data.success` benar-benar ada dan bernilai `true`
      if (data && data.success) {
        alert('Login Berhasil');
        navigate('/home'); // Arahkan ke halaman home jika login berhasil
      } else {
        alert(data.message || 'Username atau password salah');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Terjadi kesalahan saat login. Silakan coba lagi.');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <h2>Login</h2>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
